
import { reactive, ref, SetupContext, toRefs,defineComponent } from "vue";
import { _Client } from "@/api-client";
import {
  AllRoleRep,
  CreateAdminUserCmd,
  SearchAzureAdUserRep,
} from "@/api-client/client";
import { Form } from 'ant-design-vue';

const useForm=Form.useForm;
export default defineComponent({
  setup(props: {}, context: SetupContext) {
    const formStyle = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
    const modalVisible = ref<boolean>(false);
    const saveLoading = ref<boolean>(false);

    const roles = ref<AllRoleRep[]>([]);
    const searchRoles=ref<AllRoleRep[]>([]);
    const aadUsers = ref<SearchAzureAdUserRep[]>([]);

    const formState = reactive<{
      createUserValue: CreateAdminUserCmd;
      createUserRules: any;
    }>({
      createUserValue: CreateAdminUserCmd.fromJS({
        email: "",
        roleIds: [],
        isEnabled: true,
      }),
      createUserRules: {
        email: [
          {
            required: true,
            message: "Please input email",
            trigger: "blur",
          },
        ],
        roleIds: [
          {
            type: "array",
            required: true,
            message: "Please select role",
            trigger: "blur",
          },
        ],
        isEnabled: [
          {
            type: "boolean",
            required: true,
            message: "Please select status",
            trigger: "blur",
          },
        ],
      },
    });

    const formItem = useForm(
      formState.createUserValue,
      formState.createUserRules
    );

    async function getAllRole() {
      roles.value = await _Client.roleClient.searchRoleAll();
      searchRoles.value=roles.value;
    }

    function aadUserQuery(value: string) {
      if (value && value.length > 3) {
        _Client.adminUserClient.queryAzureAdUserByEmail(value).then((res) => {
          aadUsers.value = res;
        });
      }
    }

    async function showModal() {
      modalVisible.value = true;
      await getAllRole();
    }

    function hideModal() {
      modalVisible.value = false;
    }

    async function saveUser() {
      await formItem.validate();
      saveLoading.value = true;
      try {
        await _Client.adminUserClient.create(formState.createUserValue);
        context.emit("success");
        hideModal();
      } finally {
        saveLoading.value = false;
      }
    }

    function roleSearchHandler(value:string){
      searchRoles.value=roles.value.filter(x=>x.name!.indexOf(value)>-1);
    }

    function roleFocusHandler(){
      searchRoles.value=roles.value;
    }

    return {
      formStyle,
      modalVisible,
      saveLoading,
      showModal,
      hideModal,
      ...toRefs(formState),
      aadUsers,
      validateInfos: formItem.validateInfos,
      resetFields: formItem.resetFields,
      saveUser,
      aadUserQuery,
      searchRoles,
      roleSearchHandler,
      roleFocusHandler
    };
  },
})
