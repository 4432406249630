
import { reactive, ref, SetupContext,defineComponent, toRefs } from "vue";
import { _Client } from "@/api-client";
import {
  AllRoleRep,
  GetAdminUserInfoRep,
  ModifyAdminUserCmd,
  SearchAdminUserRep,
} from "@/api-client/client";
import { Form } from 'ant-design-vue';

const useForm=Form.useForm;
export default defineComponent({
  setup(props: {}, context: SetupContext) {
    const formStyle = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
    const modalVisible = ref<boolean>(false);
    const saveLoading = ref<boolean>(false);

    const roles = ref<AllRoleRep[]>([]);
    const searchRoles = ref<AllRoleRep[]>([]);
    const editUserEmail = ref<string>("");

    const formState = reactive<{
      editUserValue: ModifyAdminUserCmd;
      editUserRules: any;
    }>({
      editUserValue: ModifyAdminUserCmd.fromJS({
        adminUserId: "",
        isEnabled: false,
        roleIds: [],
      }),
      editUserRules: {
        roleIds: [
          {
            type: "array",
            required: true,
            message: "Please select role",
            trigger: "blur",
          },
        ],
        isEnabled: [
          {
            type: "boolean",
            required: true,
            message: "Please select status",
            trigger: "blur",
          },
        ],
      },
    });

    const formItem = useForm(formState.editUserValue, formState.editUserRules);

    async function getAllRole() {
      roles.value = await _Client.roleClient.searchRoleAll();
      searchRoles.value=roles.value
    }

    async function getUserDetail(id: string) {
      var userDetail = await _Client.adminUserClient.getUserInfoById(id);
      editUserEmail.value = <string>userDetail.email;
      formState.editUserValue.roleIds = <string[]>userDetail.roleIds;
      formState.editUserValue.adminUserId = userDetail.id;
      formState.editUserValue.isEnabled = userDetail.isEnabled;
    }

    async function showModal(params: SearchAdminUserRep) {
      modalVisible.value = true;
      await getAllRole();
      await getUserDetail(params.id);
    }

    function hideModal() {
      modalVisible.value = false;
    }

    async function saveUser() {
      await formItem.validate();
      try {
        saveLoading.value = true;
        await _Client.adminUserClient.modify(formState.editUserValue);
        context.emit("success");
        hideModal();
      } finally {
        saveLoading.value = false;
      }
    }

    function roleSearchHandler(value:string){
      searchRoles.value=roles.value.filter(x=>x.name!.indexOf(value)>-1);
    }

    function roleFocusHandler(){
      searchRoles.value=roles.value;
    }

    return {
      formStyle,
      modalVisible,
      saveLoading,
      showModal,
      hideModal,
      editUserEmail,
      ...toRefs(formState),
      validateInfos: formItem.validateInfos,
      resetFields: formItem.resetFields,
      saveUser,
      searchRoles,
      roleSearchHandler,
      roleFocusHandler
    };
  },
})
