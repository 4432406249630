import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { UndoOutlined } from "@ant-design/icons-vue";
import Pagination from '@/components/Pagination/index.vue'
import { _Client } from '@/api-client';
import { ChangeAdminUserStatusCmd, PagedResultOfSearchAdminUserRep, SearchAdminUserQuery, SearchAdminUserRep } from '@/api-client/client';
import AddUser from './components/AddUser.vue'
import EditUser from './components/EditUser.vue'
import LogDetail from '@/components/LogDetail/index.vue'
import { useRouter } from "vue-router";
import { LogDetailModel } from '@/api-client/modal';

export default defineComponent({
  components: {
    UndoOutlined,
    Pagination,
    AddUser,
    EditUser,
    LogDetail
  },
  setup() {
    const { push, currentRoute } = useRouter();
    const tableColumns = [
      {
        title: 'User Email',
        dataIndex: 'email',
        key: 'UserEmail'
      },
      {
        title: 'Region/Role',
        key: 'region',
        slots: { customRender: 'region' }
      },
      {
        title: 'Status',
        width: 95,
        key: 'status',
        slots: { customRender: 'status' }
      },
      {
        title: 'Operation',
        width: 200,
        key: 'operation',
        slots: { customRender: 'operation' }
      }
    ]

    const state = reactive({
      searchParams: new SearchAdminUserQuery(),
      tableSource: new PagedResultOfSearchAdminUserRep(),
      tableLoading: false
    })

    onMounted(async () => {
      await searchList();
    })

    const logOperation = () => {
      const logDetailRef = ref(null)

      const logState = reactive<{ logDetail: LogDetailModel }>({
        logDetail: new LogDetailModel()
      })
      async function showLogDialog(user: SearchAdminUserRep) {
        await getLogList()
      }
      async function getLogList() {
        try {
          let logDetail = await _Client.actionOperationLogClient.adminUserQuery('0d7b27a4-fdd4-4c3e-9d22-59b8a128d1a6');
          logState.logDetail = new LogDetailModel({
            list: [...logDetail]
          })
        }
        finally {
          (logDetailRef.value as any).showLogModal = true
        }
      }

      return { logDetailRef, ...toRefs(logState), showLogDialog, getLogList }
    }

    async function searchList() {
      state.searchParams.pageIndex = 1;
      await searchUser();
    }

    async function reset() {
      state.searchParams.keyword = "";
      state.searchParams.pageIndex = 1;
      await searchUser();
    }

    async function searchUser() {
      state.tableLoading = true;
      state.tableSource = await _Client.adminUserClient.search(state.searchParams)
      state.tableLoading = false;
    }

    function AddOrModifyOperateion() {
      const addUserRef = ref(null)
      const editUserRef = ref(null)
      const viewLogUserRef = ref(null)

      function addUser() {
        (addUserRef.value as any).showModal();
      }

      function editUser(params: SearchAdminUserRep) {
        (editUserRef.value as any).showModal(params);
      }

      async function statusChange(params: SearchAdminUserRep) {
        await _Client.adminUserClient.changeStatus(ChangeAdminUserStatusCmd.fromJS({ adminUserId: params.id, status: !params.isEnabled }))
        await searchUser()
      }

      return { addUserRef, editUserRef, viewLogUserRef, addUser, editUser, ...logOperation(), statusChange }
    }
    return {
      tableColumns,
      searchList,
      searchUser,
      reset,
      ...toRefs(state),
      ...AddOrModifyOperateion()
    }
  }
})
